import dayjs from "dayjs"
import { ICell } from "../Cell"

export const DaysRemainingCell = (props: ICell) => {
  if (props.column.daysremainingdataindex === undefined) {
    return <p className="text-xs text-gray-300">No date column selected</p>
  }

  const date = props.record[props.column.daysremainingdataindex]
  if (date === undefined) {
    return <p className="text-xs text-gray-300">No date selected</p>
  }

  const remainingDays = dayjs(date).diff(dayjs(), "days")
  if (remainingDays < 7) {
    return <p className="text-red-400">{remainingDays} dage</p>
  }
  if (remainingDays >= 7 && remainingDays < 14) {
    return <p className="text-orange-400">{remainingDays} dage</p>
  } else {
    return <p className="text-green-400">{remainingDays} dage</p>
  }
}
